<template>
    <b-container>
        <div class="section-title budget">Справочник Целевых индикаторов</div>
        <b-table :fields="tableFields" bordered no-border-collapse striped thead-class="bold_header" :items="tableData">
            <template #cell(f2)="data">
                <div class="text-left">
                    <a v-if="data.item.f6" :href="data.item.f6">{{ data.value }}</a>
                    <a v-else>{{ data.value }}</a>
                </div>
            </template>
        </b-table>
        <div class="text-right mt-3">
            <b-button class="m-1">Скачать в xls</b-button>
        </div>
    </b-container>
</template>

<script>
export default {
    name: 'TargetDict',
    data() {
        return {
            options: [
                2022, 2023, 2024
            ],
            select: 2022,
            options1: [
                {
                    value: 'обязательства',
                    text: 'Обязательства'
                },
                {
                    value: 'платежи',
                    text: 'Платежи'
                }
            ],
            select1: 'обязательства',
            tableFields: [
                {
                    key: 'f1',
                    label: 'Уровень документа СГП'
                },
                {
                    key: 'f2',
                    label: 'Документ СГП'
                },
                {
                    key: 'f3',
                    label: 'Цели'
                },
                {
                    key: 'f4',
                    label: 'Целевые индикаторы'
                },
                {
                    key: 'f5',
                    label: 'Ответственный АБП'
                }

            ],
            tableData: [
                {
                    'f1': '1-уровень',
                    'f2': 'Стратегия развития Казахстана до 2025 года',
                    'f3': '',
                    'f4': '',
                    'f5': ''
                },
                {
                    'f1': '',
                    'f2': 'Общенациональные приоритеты',
                    'f3': '',
                    'f4': '',
                    'f5': ''
                },
                {
                    'f1': '',
                    'f2': 'Национальный план развития',
                    'f3': 'Улучшение качества и доступности образования',
                    'f4': 'Уровень удовлетворенности населения качеством дошкольного/среднего образования',
                    'f5': 'УО'
                },
                {
                    'f1': '',
                    'f2': '',
                    'f3': '',
                    'f4': 'Оценка качества школьного образования по результатам теста PISA (отчет ОЭСР)',
                    'f5': 'УО'
                },
                {
                    'f1': '',
                    'f2': 'План территориального развития страны',
                    'f3': '',
                    'f4': '',
                    'f5': ''
                },
                {
                    'f1': '2-уровень Национальные проекты',
                    'f2': 'Программа развития образования и науки ',
                    'f3': 'Улучшение качества и доступности образования',
                    'f4': 'Строительство школ в целях ликвидации трехсменных школ и дефицита ученических мест, ед.',
                    'f5': 'УО, УС'
                },
                {
                    'f1': '',
                    'f2': '',
                    'f3': '',
                    'f4': '',
                    'f5': 'УПТиАД'
                },
                {
                    'f1': '',
                    'f2': 'Нурлы жер',
                    'f3': 'Улучшение состояния местных дорог',
                    'f4': 'Проведение ремонтных работ, км',
                    'f5': ''
                },
                {
                    'f1': '3-уровень',
                    'f2': 'План развития Павлодарской области',
                    'f3': 'Улучшение состояния местных дорог',
                    'f4': 'Доля автодорог местного значения, находящихся в хорошем и удовлетворительном состоянии, %',
                    'f5': 'УПТиАД',
                    'f6': '/#/demo-budget-execution/sgp/target-indicators'
                },
                {
                    'f1': '',
                    'f2': '',
                    'f3': 'Улучшение качества и доступности образования',
                    'f4': 'Снижение дефицита ученических мест в г. Павлодаре (специфичный), кол-во мест',
                    'f5': 'УО, УС'
                }
            ]

        };
    }
};
</script>

<style scoped>

</style>